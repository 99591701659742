<template>
	<v-card :color="statusColor" class="my-2 mx-auto flex-grow-1 flex-shrink-0 d-flex flex-column" style="width: 100%" rounded="xl">
		<v-card-title class="flex-grow-0">
			{{ degree.acronym }}
			<v-spacer />
			<template v-if="degree.faculty.discord && user.setupInfo.accountType.student.value">
				<v-btn raised rounded small :href="degree.faculty.discord" target="_blank" color="#7289da"><v-icon>mdi-discord</v-icon> DISCORD</v-btn>
			</template>
			<v-spacer />
			<v-dialog v-model="dialog" persistent max-width="350">
				<template v-slot:activator="{ on }">
					<v-btn icon v-on="on">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</template>
				<v-card rounded="xl">
					<v-card-title>
						<span class="headline">{{ $t('courses.areYouSure') }}</span>
					</v-card-title>
					<v-card-text>{{ $t('studies.removeDegree') }}</v-card-text>
					<v-card-actions>
						<v-spacer />
						<v-btn color="blue darken-1" text @click="dialog = false">{{ $t('offers.cancel') }}</v-btn>
						<v-btn color="error darken-1" text :loading="loadingRemoval" :disabled="loadingRemoval" @click="callRemoveDegree()">
							{{ $t('settings.remove') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-card-title>
		<v-card-subtitle class="font-weight-bold flex-grow-0" v-if="user.setupInfo.accountType.student.value">
			<v-tooltip bottom max-width="500" open-on-click>
				<template v-slot:activator="{ on, attrs }">
					<v-icon color="yellow accent-2" v-bind="attrs" v-on="on">
						mdi-information
					</v-icon>
				</template>
				<span>{{ statusMessage }}</span>
			</v-tooltip>
			{{ statusText }}
			<span class="float-right text-body-2">
				<span>
					<v-icon :color="materialColor">{{ materialIcon }}</v-icon>
					{{ $t('explorer.material') }}
				</span>
				<span>
					<v-icon :color="jobsColor">{{ jobsIcon }}</v-icon>
					OverSOS Jobs
				</span>
			</span>
		</v-card-subtitle>
		<v-card-text class="d-flex flex-column flex-grow-1 pb-1">
			<span class="font-weight-bold text-body-1">{{ degree.name }}</span>
			<v-chip-group column class="my-1">
				<v-chip>
					{{ degree.faculty.acronym }}
				</v-chip>
				<v-chip>
					{{ degree.university.acronym }}
				</v-chip>
			</v-chip-group>
		</v-card-text>
		<v-card-actions class="pt-0 flex-grow-0" v-if="user.setupInfo.accountType.student.value">
			<v-icon dense :color="student.verified.value ? 'success' : 'error'" class="mr-1">
				{{ student.verified.value ? 'mdi-check-circle' : 'mdi-close-circle' }}
			</v-icon>
			<template v-if="student.verified.value">
				<i18n path="studies.linkedVerified" tag="p" class="text-subtitle-2 my-0 py-0">
					<template #email>
						<span style="word-break: break-word">{{ student.email }}</span>
					</template>
				</i18n>
			</template>
			<template v-else>
				<i18n path="studies.linkedNotVerified" tag="p" class="text-subtitle-2 my-0 py-0">
					<template #email>
						<span style="word-break: break-word">{{ student.email }}</span>
					</template>
				</i18n>
			</template>
		</v-card-actions>
		<v-card-actions class="pt-0 flex-grow-0">
			<v-btn rounded small @click="callNotEndStudy()" v-if="studyEnded" :loading="loadingEndStudy" :disabled="loadingEndStudy">
				{{ $t('studies.markAsNotEnded') }}
			</v-btn>
			<v-spacer />
			<v-chip outlined v-if="studyEnded">
				{{ $t('studies.ended') }}
			</v-chip>
			<v-btn rounded small @click="callEndStudy()" v-if="!studyEnded" :loading="loadingEndStudy" :disabled="loadingEndStudy">
				{{ $t('studies.markAsEnded') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'DegreeCard',
	props: {
		studyID: {
			type: Number,
			default: 0
		},
		student: {
			type: Object,
			required: true
		},
		degree: {
			type: Object,
			required: true
		},
		studyEnded: {
			type: Boolean,
			required: true
		},
		sentStudentEmail: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			dialog: false,
			loadingEndStudy: false,
			loadingRemoval: false
		}
	},
	computed: {
		statusColor() {
			if (!this.user.setupInfo.accountType.student.value) {
				return `indigo ${this.$vuetify.theme.dark ? 'darken' : 'lighten'}-4`
			} else if (this.degree.status.operational.value) {
				return `green ${this.$vuetify.theme.dark ? 'darken' : 'lighten'}-4`
			} else if (this.degree.status.helpless.value) {
				return `lime ${this.$vuetify.theme.dark ? 'darken' : 'lighten'}-4`
			} else if (this.degree.status.empty.value) {
				return `blue ${this.$vuetify.theme.dark ? 'darken' : 'lighten'}-4`
			} else if (this.degree.status.minimal.value) {
				return `orange ${this.$vuetify.theme.dark ? 'darken' : 'lighten'}-3`
			} else {
				return `red ${this.$vuetify.theme.dark ? 'darken' : 'lighten'}-4`
			}
		},
		statusText() {
			if (this.degree.status.operational.value) {
				return this.$t('degrees.statusOperational')
			} else if (this.degree.status.helpless.value) {
				return this.$t('degrees.statusHelpless')
			} else if (this.degree.status.empty.value) {
				return this.$t('degrees.statusEmpty')
			} else if (this.degree.status.minimal.value) {
				return this.$t('degrees.statusMinimal')
			} else {
				return this.$t('degrees.statusClosed')
			}
		},
		statusMessage() {
			if (this.degree.status.operational.value) {
				return this.degree.status.operational.text
			} else if (this.degree.status.helpless.value) {
				return this.degree.status.helpless.text
			} else if (this.degree.status.empty.value) {
				return this.degree.status.empty.text
			} else if (this.degree.status.minimal.value) {
				return this.degree.status.minimal.text
			} else {
				return this.degree.status.closed.text
			}
		},
		jobsColor() {
			if (this.degree.status.operational.value) {
				return 'green accent-4'
			} else if (this.degree.status.helpless.value) {
				return 'green accent-4'
			} else if (this.degree.status.empty.value) {
				return 'green accent-4'
			} else if (this.degree.status.minimal.value) {
				return 'green accent-4'
			} else {
				return 'red accent-4'
			}
		},
		jobsIcon() {
			if (this.degree.status.operational.value) {
				return 'mdi-briefcase-check'
			} else if (this.degree.status.helpless.value) {
				return 'mdi-briefcase-check'
			} else if (this.degree.status.empty.value) {
				return 'mdi-briefcase-check'
			} else if (this.degree.status.minimal.value) {
				return 'mdi-briefcase-check'
			} else {
				return 'mdi-briefcase-off'
			}
		},
		materialColor() {
			if (this.degree.status.operational.value) {
				return 'green accent-4'
			} else if (this.degree.status.helpless.value) {
				return 'yellow accent-2'
			} else if (this.degree.status.empty.value) {
				return 'red accent-4'
			} else if (this.degree.status.minimal.value) {
				return 'red accent-4'
			} else {
				return 'red accent-4'
			}
		},
		materialIcon() {
			if (this.degree.status.operational.value) {
				return 'mdi-text-box-check'
			} else if (this.degree.status.helpless.value) {
				return 'mdi-text-box-plus'
			} else if (this.degree.status.empty.value) {
				return 'mdi-text-box-remove'
			} else if (this.degree.status.minimal.value) {
				return 'mdi-skull-crossbones'
			} else {
				return 'mdi-skull-crossbones'
			}
		},
		...mapGetters({
			user: 'users/user'
		})
	},
	methods: {
		callRemoveDegree() {
			this.loadingRemoval = true
			this.removeDegree({ student: this.student, degree: this.degree }).then(() => {
				this.loadingRemoval = false
			})
		},
		callEndStudy() {
			this.loadingEndStudy = true
			this.endStudy({ studyID: this.studyID }).then(() => {
				this.loadingEndStudy = false
			})
		},
		callNotEndStudy() {
			this.loadingEndStudy = true
			this.notEndStudy({ studyID: this.studyID }).then(() => {
				this.loadingEndStudy = false
			})
		},
		...mapActions('studies', ['sendVerifyStudentEmail', 'endStudy', 'notEndStudy']),
		...mapActions('degrees', ['removeDegree'])
	}
}
</script>

<style scoped></style>
